$OFF_WHITE: #fafafa;
$Text: #263042;
$Green: #00d170;
$Light_green: #edf6ee;
$Orange: #ff6e00;
$Light_Orange: #fcf5ed;
$Blue: #32a9e6;
$Light_Blue: #e7f4f3;
$Red: #eb413c;
$Yellow: #ffae33;
$font: sans-serif;
$primary: #333;
$secondary: #999;
