@import '../../sass/_variables';
.login {
  //width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  justify-content: space-between;
  align-items: center;
  //padding: 15px;
  //padding-left: 150px;
  button:hover {
    background-color: $Green;
    color: white;
  }
}
.login-wrap {
  width: 350px;
  background: $OFF_WHITE;
  border-radius: 25px;
  overflow: hidden;
  padding: 75px 40px 75px 40px;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}
.login-form-btn {
  background: $Green;
  color: $OFF_WHITE;
  width: 170px;
  height: 40px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 300;
  font-family: 'Barlow', sans-serif;
  outline: none;
  border: none;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  .CircularProgress {
    color: $OFF_WHITE !important;
    width: 25px !important;
    height: 25px !important;
  }
}
button:focus {
  outline: none;
}

.login-form-title {
  font-size: 24px;
  font-weight: 400;
  //font-family: Barlow;
  color: $primary;
  display: block;
  line-height: 1.2;
  padding-bottom: 12px;
  padding-left: 5px;
}
.login-form-logo {
  display: block;
  width: -webkit-fill-available;
  padding: 5px;
  padding-bottom: 70px;
}
.login-input-grup {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #adadad;
  margin-bottom: 37px;
}
.login-input {
  //font-family: Poppins-Regular;
  font-size: 15px;
  font-weight: 600;
  color: $primary;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
  padding: 0 5px;
}
input {
  outline: none;
  border: none;
}
button,
input {
  overflow: visible;
}
.login-input-label {
  font-size: 15px;
  font-weight: 500;
  //font-family: Barlow;
  color: $Green;
}
.MuiAlert-standardError {
  position: absolute;
  width: 100%;
}
.login-page-img-box {
  //background-image: url('assets/img/bg.svg');
  width: 700px;
  height: 700px;
}
.login-page-img {
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}
